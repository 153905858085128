exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-courses-common-accessibility-mistakes-js": () => import("./../../../src/pages/courses/common-accessibility-mistakes.js" /* webpackChunkName: "component---src-pages-courses-common-accessibility-mistakes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notes-index-js": () => import("./../../../src/pages/notes/index.js" /* webpackChunkName: "component---src-pages-notes-index-js" */),
  "component---src-pages-notes-slug-js": () => import("./../../../src/pages/notes/[...slug].js" /* webpackChunkName: "component---src-pages-notes-slug-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "component---src-templates-note-js": () => import("./../../../src/templates/note.js" /* webpackChunkName: "component---src-templates-note-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

